import React, { useEffect, useState } from 'react';
import GloballyLogos from './GloballyLogos';
import Title from './Title';    
import Aos from "aos";
import "aos/dist/aos.css";
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import useApiList from '../hooks/useApiList';

const HomeGlobally = () => {

    const { data } = useApiList();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.2,
      });

    const [logos,setLogos] = useState([]);

    const svgAnimation = {
        hidden:{ pathLength: 0 },
              visible:{ pathLength: 1 ,
                transition:{
                  duration: 2,
                  ease: "easeInOut",
              }
              },
              
      }
    useEffect(()=>{
        setLogos(data.logoList)
    }, [data]);

    useEffect(() => {Aos.init({ duration: 1200 });}, []);
    
  return (
    <section className="trusted"ref={ref}>
        <svg className="HRmotion" xmlns="http://www.w3.org/2000/svg" width="243" height="464" viewBox="0 0 243 464" fill="none">
        <motion.path 
         
         initial="hidden"
         animate={inView ? 'visible' : 'hidden'}
         variants={svgAnimation}

            d="M296.715 49.4654C196.283 0.115273 9.4458 -53.3905 65.5485 127.387C135.677 353.36 161.651 467.644 0.613281 462.449" stroke="#0CB7C5" strokeWidth="2"/>
        </svg>
        <div className="wrapper">
            <Title 
                title="We’re Globally"
                blueTitle='Trusted'
                paragraph="Unwavering Trust: Where Confidence and Reliability Converge."
              > 
              <div className="transperent-txt" data-aos="fade-down"><img src="/images/Globallytxt.svg" alt="Globally" /></div>
            </Title>
               
            <div className="image-block">
                <img src="/images/trusted-bg.png" alt="trusted"data-aos="fade-left" />
            </div>
        </div>
        <div className="logos-block">
            <div className="logos-boxes">
                {logos && logos.map((item, index) =>
                    <GloballyLogos
                        key={index}
                        logos={item.logo}
                    />
                )}
            </div>
            <div className="logos-boxes">
                {logos && logos.map((item, index) =>
                    <GloballyLogos
                        key={index}
                        logos={item.logo}
                    />
                )}
            </div>
        </div>
    </section>
  )
}

export default HomeGlobally;