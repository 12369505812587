import React, { useEffect, useState } from 'react';
import CurentOpeningCard from './CurentOpeningCard';
import Title from './Title';
import useApiList from '../hooks/useApiList';

const CreerCurentOpning = () => {

    const { data } = useApiList();
    const [current,setCurrent] = useState([]);

    useEffect(()=>{
        setCurrent(data.careerList);
    }, [data]);

  return (
    <section className="jobs">
        <div className="wrapper">
            <Title
                minibtnlabel="Jobs"
                title='Current'
                blueTitle='Openings'
                paragraph='Innovators Wanted Join the Codeflix Web Team and Create the Future !'
            > 
            </Title>
            <div className="current-block">
                <div className="left">
                    {current && current.map((item, index) =>
                        <CurentOpeningCard
                            key={index}
                            title={item.title}
                            years={item.years}
                            opening={item.opening}
                            bgblue={item.color}
                        />
                    )}
                </div>
            </div>
        </div>
    </section>
  )
}

export default CreerCurentOpning;