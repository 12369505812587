import React, { useEffect, useState } from 'react';
import Title from './Title';
import UpdateCards from './UpdateCards';
import useApiList from '../hooks/useApiList';

const HomeUpdate = () => {

    const { data } = useApiList();
    const [update,setUpdate] = useState([]);

    useEffect(()=>{
        setUpdate(data.updateList);
    }, [data]);

  return (
    <section className="latest-update">
        <div className="wrapper">
            <Title 
                minibtnlabel="Connections"
                title="Latest Tech"
                blueTitle='Update'
              > 
            </Title>
            <div className="letest-up-block">
                <div className="letest-box full">
                    <img src="/images/update-1.png" alt="" />
                    <div className="letest-dtl">
                        <h4 className="h6 bold">iOS Development</h4>
                        <p className="p-small">We collaborate with our clients from the initial stages of ideation and conceptualization, guiding them through the entire process—from architectural design to development and deployment of their applications.</p>
                        <a  className="">Read More <img src="/images/read-arrow.svg" /></a>
                    </div>
                </div>
                {update && update.map((item, index) =>
                    <UpdateCards
                        key={index}
                        cardImg={item.image}
                        cradTitle={item.title}
                        cardDtl={item.detail}
                    />
                )}
            </div>
        </div>
    </section>
  )
}

export default HomeUpdate;