import React from 'react'

const Title = ({title,blueTitle,paragraph,children,minibtnlabel,boldTitle}) => {

  return (
    <div className="title" data-aos="fade-down">
       <span className="mini-btn"><img src="/images/mini-btn-avg.svg" alt="btn" />{minibtnlabel}</span>
        <h1 className="h1">
            {title}<br /><span className="bold">{boldTitle}</span> <span className="blue bold">{blueTitle}</span>
        </h1>
        <p>{paragraph}</p>
       {children}
    </div>
  )
}

export default Title;