import React from 'react'
import Title from './Title';
import Button from './Button';

const ServiceBanner = () => {
  return (
    <section className="service-banner">
        <div className="wrapper">
            <Title 
                minibtnlabel="Services"
                title="Transforming Ideas"
                boldTitle='Into'
                blueTitle='Reality'
                paragraph="Powered by a diverse array of sophisticated tools and innovative platforms, our suite of technology stands at the forefront of industry standards."
              > 
              <Button label='Explore Our Vision'></Button>
            </Title>
        </div>
    </section>
  )
}

export default ServiceBanner;