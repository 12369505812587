import React, { useState, useEffect } from 'react';

const AboutProgressBar = ({
  sqSize = 76,
  initialPercentage = 0, 
  targetPercentage = 25, 
  strokeWidth = 8,
  animationDuration = 2000, 
}) => {
  const [percentage, setPercentage] = useState(initialPercentage);

  useEffect(() => {
    const increment = targetPercentage / (animationDuration / 10); 
    let currentPercentage = initialPercentage;

    const intervalId = setInterval(() => {
      currentPercentage += increment; 
      if (currentPercentage >= targetPercentage) {
        currentPercentage = targetPercentage;
        clearInterval(intervalId); 
      }
      setPercentage(currentPercentage); 
    }, 10); 

    return () => clearInterval(intervalId); 
  }, [targetPercentage, animationDuration, initialPercentage]);

  const radius = (sqSize - strokeWidth) / 2;

  const viewBox = `0 0 ${sqSize} ${sqSize}`;

  const dashArray = radius * Math.PI * 2;

  const dashOffset = dashArray - (dashArray * percentage) / 100;

  return (
    <svg
      width={sqSize}
      height={sqSize}
      viewBox={viewBox}
    >
      <circle
        className="circle-background"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />

      <circle
        className="circle-progress"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`} 
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          transition: 'stroke-dashoffset 0.1s', 
        }}
      />

      <text
        className="circle-text"
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
      >
        {`${Math.round(percentage)}%`}
      </text>
    </svg>
  );
};

export default AboutProgressBar;
