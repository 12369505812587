import React from 'react'
import ServiceBanner from './ServiceBanner';
import Chat from './Chat';
import ServiceDevelopment from './ServiceDevelopment';

const Service = () => {
  return (
    <>
      <ServiceBanner />
      <ServiceDevelopment />
      <Chat />
   </>
  )
}

export default Service;