import React from 'react'
import HomeClientBox from './HomeClientBox';
import { baseUrl } from '../utils/constant';

const HomeClientSlideBox = ({topItem,bottomItem}) => {
    return (
        <div className="client-box">
            <HomeClientBox
                position='top'
                boxImg={baseUrl + topItem.image}
                boxTitle={topItem.title}
                boxCompanyName={topItem.companyName}
                boxDtl={topItem.detail}
            />
            <HomeClientBox
                position='bottom'
                boxImg={baseUrl + bottomItem.image}
                boxTitle={bottomItem.title}
                boxCompanyName={bottomItem.companyName}
                boxDtl={bottomItem.detail}
            />

        </div>
    )
}

export default HomeClientSlideBox;