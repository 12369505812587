import React, { useEffect, useState } from 'react';
import Title from './Title';
import Button from './Button';
import AboutGalleryImg from './AboutGalleryImg';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutGallerySliderCard from './AboutGallerySliderCard';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import useApiList from '../hooks/useApiList';

const AboutGalley = () => {

    const { data } = useApiList();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.2,
      });
      const svgAnimation = {
        hidden:{ pathLength: 0 },
              visible:{ pathLength: 1 ,
              
                transition:{
                  duration: 2,
                  ease: "easeInOut",
              }
              },
      }
    const [aboutGallery,setAboutGallery] = useState([]);
    const [aboutGallerySlide,setAboutGallerySlide] = useState([]);

    useEffect(()=>{
        setAboutGallery(data.aboutImageList);
        setAboutGallerySlide(data.aboutSliderList);
    }, [data]);

    const settings = {
        dots:true,
        fade:true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToShow: 1.3,
        dotsclassName: "slider-dots",
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        pauseOnHover: false,
        pauseOnFocus: false,
    };

  return (
    <section className="gallery" ref={ref} >
        <svg className="HRmotion" xmlns="http://www.w3.org/2000/svg" width="197" height="426" viewBox="0 0 197 426" fill="none">
            <motion.path 
             initial="hidden"
             animate={inView ? 'visible' : 'hidden'}
             variants={svgAnimation}
            
            d="M207.703 1.9502C92.9793 22.4362 -96.7827 87.9916 61.9566 186.325C260.381 309.242 307.295 273.436 111.949 424.912" stroke="#09C2C1" strokeWidth="2"/>
        </svg>
        <div className="life_gallery">
            <div className="gallery_row">
                <div className="gallery_col">
                    {aboutGallery && aboutGallery.map((item, index) =>
                        <AboutGalleryImg
                            key={index}
                            img={item.image}
                        />
                    )}
                </div>
                <div className="gallery_col gallery_col_reverse">
                    {aboutGallery && aboutGallery.map((item, index) =>
                        <AboutGalleryImg
                            key={index}
                            img={item.image}
                        />
                    )}
                </div>
                <div className="gallery_col">
                    {aboutGallery && aboutGallery.map((item, index) =>
                        <AboutGalleryImg
                            key={index}
                            img={item.image}
                        />
                    )}
                </div>
            </div>
        </div>
        <div className="wrapper">
            <div className="gallery-img">
                <div className='slider-wrap'>
                <Slider {...settings} className='image-slider'>
                    {aboutGallerySlide && aboutGallerySlide.map((item, index) =>
                        <AboutGallerySliderCard
                            key={index}
                            aboutSlideImg={item.slideImage}
                        />
                    )}
                </Slider>
                <div className="title-gallery">
                    <h6 className="h6 bold">Codeflix Diaries</h6>
                    <p className="p-small">Revealing the Vibrant Life Beyond Lines of Code</p>
                </div>
                </div>
            </div>
            <Title 
                minibtnlabel="Gallery"
                title="Life at"
                blueTitle='Codeflix'
                paragraph="At Codeflix, it's not just about the lines of code; it's a community that celebrates milestones with lively get-togethers and an annual trip that brings us closer together outside the screen. Beyond the algorithms, we bond over shared experiences, creating lasting memories that enhance our teamwork and camaraderie."
                > 
                <Button label='Join Us'></Button>
            </Title>
        </div>
        
    </section>
  )
}

export default AboutGalley;