import React from 'react'
import { Link } from 'react-router-dom';
import { baseUrl } from '../utils/constant';

const ServiceDevCard = ({title,subTitle,dtl,titleImg,img,size,subImg}) => {
  return (
    <div className={" cards col-" + size } data-aos="zoom-in">
        <div className="uniqe-card">
            <h3 className="h3 bold">{title} <br /> {subTitle}</h3>
            {titleImg && <img src={baseUrl + titleImg} alt="dots" />}
        </div>
        <p>{dtl}</p>
        {subImg && <img src={baseUrl + subImg} alt="service img" className="img-6" />}
        <img src={baseUrl + img} alt="service img" className="img-2" />
        <Link><img src="/images/service-card-arrow.svg" alt="arrow" /></Link>
    </div>
  )
}

export default ServiceDevCard;