import React from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {
 
  return (
    <footer>
     <div className="wrapper">
        <h5 className="h5 blue bold">Contact Us</h5>
        <div className="top">
            <div className="footer-card">
                <p className="bold blue">Main Office</p>
                <p>2464 Royal Ln. Mesa, New Jersey 45463</p>
            </div>
            <div className="footer-card">
                <p className="bold blue">Contact Us</p>
                <a href="mailto:E-Mail: Info@codeflixweb.com">E-Mail: Info@codeflixweb.com</a>
                <a href="tel:+91 656 658 9852">Phone No: +91 656 658 9852</a>
            </div>
        </div>
        <div className="top bottom">
            <div className="footer-card">
                <p className="bold blue">France - Office</p>
                <p>2464 Royal Ln. Mesa, New Jersey 45463</p>
            </div>
            <div className="footer-card">
                <p className="bold blue">Dubai - Office</p>
                <p>6391 Elgin St. Celina, Delaware 10299</p>
            </div>
            <div className="footer-card">
                <p className="bold blue">France - Office</p>
                <p>2464 Royal Ln. Mesa, New Jersey 45463</p>
            </div>
        </div>
            
      </div>
      <div className="copyright">
          <p>©Concept Design Codeflix Web LLP</p>
          <div className="social-icon">
              <Link   className=""><img src="/images/f-facebook.svg" alt="logo" /></Link>
              <Link className=""><img src="/images/f-instagram.svg" alt="logo" /></Link>
              <Link   className=""><img src="/images/f-linkedin.svg" alt="logo" /></Link>
          </div>
      </div>
    </footer>
  )
}

export default Footer;
