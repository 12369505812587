import React from 'react'
import Title from './Title';
import Button from './Button';
const HomeAbout = () => {
  return (
    <section className="about-sec">
        <div className="wrapper">
            <Title 
                minibtnlabel="About Us"
                title="Innovating Tomorrow's "
                boldTitle='Solutions'
                blueTitle='Today'
                paragraph="In an increasingly digital world, navigating the realm of information technology can be complex. That's where we come in. Let us be your trusted IT partner, providing the experience and insight necessary to empower your business."
              > 
              <Button label='Explore Our Vision'></Button>
            </Title>
            <div className="about-img-block" data-aos="fade-down">
                <div className="col">
                    <img src="/images/about-1.png" alt="img" />
                </div>
                <div className="col bottom">
                    <img src="/images/about-2.png" alt="img" />
                    <img src="/images/about-3.png" alt="img" />
                </div>
            </div>
        </div>   
    </section>
  )
}

export default HomeAbout;