import React from 'react';
import { baseUrl } from '../utils/constant';

const ServiceCard = ({img,title,subTitle}) => {
  return (
    <div className="service-card" data-aos="zoom-in">
        <div className="image">
            <img src="/images/service-rect.svg" className="rect" alt="rect" />
            <img src={baseUrl + img} alt="phone" />
        </div>
        <h4 className="h4 bold">{title}</h4>
        <p className="p-small">{subTitle}</p>
        <a  className="">{'Read More >>'}</a>
    </div>
  )
}

export default ServiceCard;