import React from 'react'
import AboutBanner from './AboutBanner';
import Chat from "./Chat";
import AboutGols from './AboutGols';
import AboutOurValues from './AboutOurValues';
import AboutTech from './AboutTech';
import AboutGalley from './AboutGalley';

const AboutUs = () => {
  return (
   <>
    <AboutBanner />
    <AboutGols />
    <AboutOurValues />
    <AboutTech />
    <AboutGalley />
     <Chat />
   </>
  )
}

export default AboutUs