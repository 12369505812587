import React, { useEffect } from 'react'
import Title from './Title';

const Chat = () => {

  useEffect(()=>{ 

    const ctnoptionMenu = document.querySelector(".select-menu-ctn"),
    ctnselectBtn = ctnoptionMenu.querySelector(".select-btn-ctn"),
    ctnoptions = ctnoptionMenu.querySelectorAll(".option-ctn"),
    ctnsBtn_text = ctnoptionMenu.querySelector(".sBtn-text-ctn");
  
  ctnselectBtn.addEventListener("click", () =>
    ctnoptionMenu.classList.toggle("active")
  );
  
  ctnoptions.forEach((option) => {
    option.addEventListener("click", () => {
      let selectedOption = option.querySelector(".option-text-ctn").innerText;
      ctnsBtn_text.innerText = selectedOption;
  
      ctnoptionMenu.classList.remove("active");
    });
  });

   },[]) 
  return (
    <section className="chat-sec">
        <div className="wrapper" data-aos="fade-down">
            <Title 
                minibtnlabel="Connections"
                title="Got a project?"
                blueTitle='Let’s Have a Chat'
              > 
            </Title>
            <div className="form-block">
                    <form >
                        <input type="email" placeholder="Email"  />
                        <div className="language">
                            <div className="select-menu-ctn">
                                <img src="/images/drop-arrow.svg" alt="arrow" />
                                <div className="select-btn-ctn">
                                  <span className="sBtn-text-ctn">Subject</span>
                                </div>
                                <ul className="options-ctn">
                                  <li className="option-ctn">
                                    <span className="option-text-ctn">English</span>
                                  </li>
                                  <li className="option-ctn">
                                    <span className="option-text-ctn">Hindi</span>
                                  </li>
                                </ul>
                              </div>
                        </div>
                        <input type="text" placeholder="Message" required />
                        <button  className="custom-btn ">Get In Touch<img src="/images/arrow.svg" alt="arrow" /></button>
                    </form>
                </div>
        </div>
                
    </section>
  )
}

export default Chat