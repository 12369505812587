import React, { useState , useEffect  } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [openLanguage, setOpenlanguage] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [linkItem, setLinkItem] = useState([
        {to: '/home', name: 'Home'},
        {to: '/service', name: 'Services'},
        {to: '/about-us', name: 'About Us'},
        {to: '/career', name: 'Career'},
        {to:'/contact-us', name: 'Contact Us'}
    ]);
    useEffect(() => {
      if (menuActive) {
        document.body.classList.add('menu-active');
      } else {
        document.body.classList.remove('menu-active');
      }
  
      return () => {
        document.body.classList.remove('menu-active');
      };
    }, [menuActive]);
  
    const toggleMenu = () => {
      setMenuActive((prev) => !prev);
    };

    useEffect(() => {
      
      //scroll top header js
      window.addEventListener("scroll", function() {
        var scrollPosition = window.scrollY || window.pageYOffset;
        var header = document.querySelector("header");
        var topNav = document.querySelector("header .top-nav");
        if (scrollPosition >= 50) {
            header.classList.add("fixed");
            topNav.classList.remove("show");  
        } else {
            header.classList.remove("sticky");  
            topNav.classList.add("show");
        }
      });

    },[]);
  
  return (
   
    <header>
            <div className="top-nav show">
                <div className="wrapper">
                    <div className="social-link">
                        <Link> <img src='/images/Instagram.svg' alt="Instagram Logo" /></Link>
                        <Link> <img src='/images/Whatsapp.svg' alt="Instagram Logo" /></Link>
                        <Link> <img src='/images/Twitter.svg' alt="Instagram Logo" /></Link>
                        <Link> <img src='/images/Linkedin.svg' alt="Instagram Logo" /></Link>
                    </div>
                    <div className="language">
                    <div className={openLanguage ? "select-menu active" : "select-menu"} onClick={() => setOpenlanguage(!openLanguage)}>
                        <img src="/images/drop-arrow.svg" alt="arrow"/>
                        <div className="select-btn">
                          <span className="sBtn-text">EN</span>
                        </div>
                        <ul className="options">
                          <li className="option">
                            <span className="option-text">HN</span>
                          </li>
                          <li className="option">
                            <span className="option-text">FR</span>
                          </li>
                          <li className="option">
                            <span className="option-text">EN</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <nav className="navbar">
                  <Link to="/home" className="logo">
                    <img src="/images/codeflix-logo.svg" alt="bag" />
                  </Link>
                    <div className="hamburgar-block">
                       <button id="menu-toggler" data-className="menu-active" className="hamburger" onClick={toggleMenu}>
                       <span className="hamburger-line hamburger-line-top"></span>
                       <span className="hamburger-line hamburger-line-middle"></span>
                       <span className="hamburger-line hamburger-line-bottom"></span>
                       </button>
                    
                       <ul id="primary-menu" className="menu nav-menu">
                          {linkItem.map((item, index) => (
                            <li className="menu-item" key={index}><Link to={item.to} className="nav__link" onClick={() => setMenuActive(false)}>{item.name}</Link></li>
                          ))}
                       </ul>
                    </div>
                    <div  className="custom-btn white">Our Work <img src="/images/bag.svg" alt="bag" /></div>
                 </nav>
            </div>
    </header>
  
  )
}

export default Header;