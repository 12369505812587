import React, { useEffect, useState } from 'react';
import Title from './Title';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeClientSlideBox from './HomeClientSlideBox';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import useApiList from '../hooks/useApiList';

const HomeClients = () => {

  const { data } = useApiList();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const svgAnimation = {
    hidden:{ pathLength: 0 },
          visible:{ pathLength: 1 ,
          
            transition:{
              duration: 2,
              ease: "easeInOut",
          }
          },
  }

    const [box,setBox] = useState([]);

    useEffect(()=>{
        setBox(data.clientList);
    }, [data]);

  const settings = {
    dots:false,
    slidesToShow: 1,
    loop:true,
    infinite:true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    arrows: true,
    prevArrow: (
        <div className="slickbtn slick-prev">
        <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" transform="matrix(-1 0 0 1 48 0.863281)" fill="url(#paint0_linear_55_392)"/>
<path d="M38 23.8633C38.5523 23.8633 39 24.311 39 24.8633C39 25.4156 38.5523 25.8633 38 25.8633L38 23.8633ZM13.7206 25.5704C13.3301 25.1799 13.3301 24.5467 13.7206 24.1562L20.0846 17.7922C20.4751 17.4017 21.1083 17.4017 21.4988 17.7922C21.8893 18.1827 21.8893 18.8159 21.4988 19.2064L15.8419 24.8633L21.4988 30.5201C21.8893 30.9107 21.8893 31.5438 21.4988 31.9343C21.1083 32.3249 20.4751 32.3249 20.0846 31.9343L13.7206 25.5704ZM38 25.8633L14.4277 25.8633L14.4277 23.8633L38 23.8633L38 25.8633Z" fill="#494949"/>
<defs>
<linearGradient id="paint0_linear_55_392" x1="3.70985" y1="2.41669e-07" x2="24" y2="48" gradientUnits="userSpaceOnUse">
<stop stopColor="#DEFFFF"/>
<stop offset="1" stopColor="#E0F1FF"/>
</linearGradient>
</defs>
</svg>

        </div>
    ),
    nextArrow: (
        <div className="slickbtn slick-next">
         <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
<rect y="0.863281" width="48" height="48" rx="24" fill="url(#paint0_linear_55_394)"/>
<path d="M10 23.8633C9.44772 23.8633 9 24.311 9 24.8633C9 25.4156 9.44772 25.8633 10 25.8633L10 23.8633ZM34.2794 25.5704C34.6699 25.1799 34.6699 24.5467 34.2794 24.1562L27.9154 17.7922C27.5249 17.4017 26.8917 17.4017 26.5012 17.7922C26.1107 18.1827 26.1107 18.8159 26.5012 19.2064L32.1581 24.8633L26.5012 30.5201C26.1107 30.9107 26.1107 31.5438 26.5012 31.9343C26.8917 32.3249 27.5249 32.3249 27.9154 31.9343L34.2794 25.5704ZM10 25.8633L33.5723 25.8633L33.5723 23.8633L10 23.8633L10 25.8633Z" fill="#494949"/>
<defs>
<linearGradient id="paint0_linear_55_394" x1="3.70985" y1="0.863281" x2="24" y2="48.8633" gradientUnits="userSpaceOnUse">
<stop stopColor="#DEFFFF"/>
<stop offset="1" stopColor="#E0F1FF"/>
</linearGradient>
</defs>
</svg>
        </div>
    ),
  };

  return (
    <section className="connections"ref={ref} >
        <svg className="HRmotion"  xmlns="http://www.w3.org/2000/svg" width="2002" height="1030" viewBox="0 0 2002 1030" fill="none">
            <motion.path 
             initial="hidden"
             animate={inView ? 'visible' : 'hidden'}
             variants={svgAnimation}
            d="M2001 29.3316C1708.02 -82.0494 1380.66 157.165 1514.32 397.358C1681.39 697.599 1645.71 1123.75 1168.39 1009.95C691.072 896.145 458.627 269.027 1 799.293" stroke="#09C1C1" strokeWidth="2"/>
        </svg>
        <div className="wrapper">
            <Title 
                minibtnlabel="Connections"
                title="What Our"
                blueTitle='Clients Says'
            > 
            </Title>
            <Slider {...settings} className='client-slider' >
              {box && box.map((item, index) => (
              index % 2 == 0 && 
              <HomeClientSlideBox 
                  key={index}
                  topItem={item}
                  bottomItem={box[index + 1] || item} 
                />
              ))}
                
            </Slider>
        </div>
    </section>
  )
}

export default HomeClients;