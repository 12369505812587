import React from 'react'
import Title from './Title';

const CreerBanner = () => {
  return (
    <section className="career-banner">
        <div className="wrapper">
        <Title
            minibtnlabel="Career"
            title='Your Career At'
            blueTitle='CodeflixWeb'
            paragraph='Innovators Wanted Join the Codeflix Web Team and Create the Future !'
            > 
        </Title>
        </div>
    </section>
   
  )
}

export default CreerBanner;