import React from 'react'
import Title from './Title';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutGols = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.2,
      });
      const svgAnimation = {
        hidden:{ pathLength: 0 },
              visible:{ pathLength: 1 ,
              
                transition:{
                  duration: 2,
                  ease: "easeInOut",
              }
              },
      }
  return (
        <section className="goals" id="goals"ref={ref}>
            <svg className="HRmotion" xmlns="http://www.w3.org/2000/svg" width="1263" height="318" viewBox="0 0 1263 318" fill="none">
                <motion.path 
                 initial="hidden"
                 animate={inView ? 'visible' : 'hidden'}
                 variants={svgAnimation}
                
                d="M-48.6934 36.2169C70.7814 -13.8135 330.789 -47.5655 415.02 217.67C520.308 549.214 880.975 -80.2722 1261.8 121.343" stroke="#09C2C1" strokeWidth="2"/>
              </svg>
            <div className="wrapper">
                <Title 
                    minibtnlabel="End Goals"
                    title="Web & Mobile application"
                    boldTitle='Development'
                    blueTitle='Team'
                > 
                </Title>
                <div className="goals-block">
                    <div className="goal-card" data-aos="zoom-in">
                        <div className="img-block">
                            <img src="/images/Vision.svg" alt="img" />
                        </div>
                        <div className="dtl">
                            <h6 className="h6"><img src="/images/dots-goals.svg"alt="dot" />Our Vision</h6>
                            <p>The agency aspires to leverage its experience and deep understanding of the business domains and needs of clients, so as to offer customized solutions that can fit their business requirements the best.</p>
                        </div>
                    </div>
                    <div className="goal-card goal"data-aos="zoom-in">
                        <div className="img-block">
                            <img src="/images/Goal.svg" alt="img" />
                        </div>
                        <div className="dtl">
                            <h6 className="h6"><img src="/images/dots-goals.svg"alt="dot" />Our Goals</h6>
                            <p>The company's mission is to make transformative digital marketing solutions accessible and affordable for businesses. Known for highly responsive designs that exceed expectations, they aim to have a significant impact on client organizations' bottom lines. Their focus is on fast-loading, efficient functionality.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default AboutGols;