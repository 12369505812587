import React from 'react'
import AboutProgressBar from './AboutProgressBar';
import { baseUrl } from '../utils/constant';

const AboutTechCards = ({img,title,percentage}) => {
  return (
    <div className="progress-card"data-aos="flip-up">
        <h6 className="h6"><img src={baseUrl + img} alt="logo" />{title}</h6>
        <AboutProgressBar  targetPercentage={percentage} />
    </div>
  )
}

export default AboutTechCards;