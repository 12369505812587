import React from 'react';
import { baseUrl } from '../utils/constant';

const AboutGalleryImg = ({img}) => {
  return (
    <div className="life_at_image">
        <img src={baseUrl + img} alt="gallery" />
    </div>
  )
}

export default AboutGalleryImg;