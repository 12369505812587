import React from 'react'
import { Link } from 'react-router-dom';
import { baseUrl } from '../utils/constant';

const HomeGallerySliderCard = ({slideimg,slidetitle,slidedtl,slidenumber}) => {
  return (
    <div className="element">
        <img src={baseUrl + slideimg} alt="image" />
        <div className="details" data-aos="fade-down">
            <div className="dtl">
                <h4 className="h4 bold">{slidetitle}</h4>
            <p>{slidedtl}</p>
            </div>
            <div className="number">{slidenumber}</div>
        </div>
        <Link className="custom-btn ">Case Study<img src="/images/arrow.svg" alt="arrow" /></Link>
    </div>
  )
}

export default HomeGallerySliderCard;