import React from 'react'
import Title from './Title';
import Button from './Button';


const Hero = () => {
  return (
    <section className="home-banner">
            <div className="wrapper">
              <Title 
                title='Your Partner For'
                blueTitle='Software Innovation'
                paragraph='Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet .'
              > 
                <Button label='Contect Us'></Button>
                 </Title>
                <div className="leptop-block">
                    <img src="/images/leptop.png" alt="leptop" />
                    <div className="leptop-names">
                        <div className="names box-1" data-aos="zoom-in">
                            <span>Maintenance</span>
                            <img src="/images/union.svg" alt="union"/>
                        </div>
                        <div className="names box-2" data-aos="zoom-in">
                            <span>UI / UX</span>
                            <img src="/images/union.svg" alt="union"/>
                        </div>
                        <div className="names box-3" data-aos="zoom-in">
                            <span>Resource Provider</span>
                            <img src="/images/union.svg" alt="union"/>
                        </div>
                        <div className="names box-4" data-aos="zoom-in"> 
                            <span>SEO</span>
                            <img src="/images/union.svg" alt="union"/>
                        </div>
                        <div className="names box-5" data-aos="zoom-in">
                            <span>App Devlopement</span>
                            <img src="/images/union.svg" alt="union"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default Hero;