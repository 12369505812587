import React from 'react'

const HomeClientBox = ({boxImg,boxTitle,boxCompanyName,boxDtl,position}) => {
  return (
    <div className={"box box-" + position}>
        <div className="box-img">
            <img src={boxImg} alt="avtar" />
        </div>
        <div className="client-dtl"data-aos="fade-left">
            <h5 className="h5">{boxTitle}</h5>
            <p>{boxCompanyName}</p>
            <p className="p-small">{boxDtl}</p>
        </div>
    </div>
  )
}

export default HomeClientBox;