import React from 'react'


const ContectUs = () => {
  return (
    <div className="contect">
        <section className="chat-sec">
            <div className="wrapper" data-aos="fade-down">
                <div className="title">
                    <img src="/images/contect-img.svg" alt="img" />
                </div>
                <div className="form-block">
                    <div className="title full">
                        <span className="mini-btn"><img src="/images/mini-btn-avg.svg" alt="btn" />Connections</span>
                        <h1 className="h1">
                            Got a project? <span className="bold blue">Let’s Have a Chat</span>
                        </h1>
                    </div>
                    <form>
                        <input type="email" placeholder="Email" required />
                        <select name="English">
                            <option value="Hindi">English</option>
                            <option value="Hindi">Hindi</option>
                            <option value="Gujrati">Gujrati</option>
                        </select>
                        <input type="text" placeholder="Message" required />
                        <button class="custom-btn ">Get In Touch<img src="/images/arrow.svg" alt="arrow" /></button>
                    </form>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ContectUs;