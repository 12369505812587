import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ServiceDevCard from './ServiceDevCard';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import useApiList from '../hooks/useApiList';

const ServiceDevelopment = () => {

  const { data } = useApiList();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });
  const svgAnimation = {
    hidden:{ pathLength: 0 },
          visible:{ pathLength: 1 ,
          
            transition:{
              duration: 2,
              ease: "easeInOut",
          }
          },
  }
  const [servicesDev,setServicesDev] = useState([]);
  useEffect(()=>{
    setServicesDev(data.serviceDevList);
  }, [data]);

  return (
      <section className="service-card"ref={ref}>
          <svg className="HRmotion" xmlns="http://www.w3.org/2000/svg" width="470" height="757" viewBox="0 0 470 757" fill="none">
              <motion.path
              initial="hidden"
              animate={inView ? 'visible' : 'hidden'}
              variants={svgAnimation}
              d="M-12.8833 2.08246C149.376 0.31896 445.852 21.6598 333.681 121.131C193.468 245.471 -142.513 332.773 333.681 438.594C809.876 544.415 -139.869 348.646 -36.6929 756.057" stroke="#CFCFCF" strokeWidth="2"/>
            </svg>
            <div className="wrapper">
                <div className="service-block">
                    {servicesDev && servicesDev.map((item, index) =>
                      <ServiceDevCard
                      key={index}
                      title={item.title}
                      subTitle={item.subTitle}
                      dtl={item.detail}
                      titleImg={item.titleImg}
                      img={item.image}
                      size={item.size}
                      subImg={item.subImage}
                      />
                    )}
                    <div className="cards col-3 transpert"data-aos="zoom-in">
                        <div className="titles">
                            <h3 className="h2 bold">Transform Business</h3>
                        <h3 className="h2 strok">Through Technology</h3>
                        </div>
                        <Link><img src="/images/blue-big-arrow.svg" alt="arrow" /></Link>
                    </div>
                </div>
            </div>
            <svg className="HRmotion bottom" xmlns="http://www.w3.org/2000/svg" width="314" height="440" viewBox="0 0 314 440" fill="none">
                <path d="M338.453 1.37598C243.308 7.16181 80.0194 69.6483 188.021 273.308C296.023 476.967 108.307 450.738 0.949219 412.166" stroke="#09C2C1"/>
            </svg>
        </section>
  )
}

export default ServiceDevelopment;