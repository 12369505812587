
//scroll top header js
window.addEventListener("scroll", function() {
    var scrollPosition = window.scrollY || window.pageYOffset;
    var header = document.querySelector("header");
    var topNav = document.querySelector("header .top-nav");
    if (scrollPosition >= 50) {
        header.classList.add("fixed");
        topNav.classList.remove("show");  
    } else {
        header.classList.remove("sticky");  
        topNav.classList.add("show");
    }
});
