import React from 'react'
import { Link } from 'react-router-dom';

const CurentOpeningCard = ({title,years,opening, bgblue}) => {
  return (
    <div className={" current-card " + bgblue} data-aos="zoom-in" >
        <h3 className="h3 bold">{title}</h3>
        <p className="bold">{years}</p>
        <p><span>{opening.join(' . ')}</span></p>
        <Link className="p-small blue bold ">Apply Now 
        <img src="/images/blue-arrow.svg" alt="arrow" /> </Link>
    </div>
  )
}

export default CurentOpeningCard;