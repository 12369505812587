import React, { useEffect, useState } from 'react';
import Title from './Title';
import AboutTechCards from './AboutTechCards';
import useApiList from '../hooks/useApiList';

const AboutTech = () => {

    const { data } = useApiList();
    const [aboutTech,setAboutTech] = useState([]);

    useEffect(()=>{
        setAboutTech(data.techList);
    }, [data]);

  return (
    <section className="technology">
        <div className="wrapper">
            <div className="title-block">
                <Title 
                    minibtnlabel="Values"
                    title="Improve and Innovate "
                    boldTitle='with the'
                    blueTitle='tech trends'
                > 
                </Title>
                <div className="tach-detail">
                    <p>Borem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. classNameName aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                </div>
            </div>
            <div className="process-block">
                <div className="progress">
                {aboutTech && aboutTech.map((item, index) =>
                    <AboutTechCards
                        key={index}
                        img={item.image}
                        title={item.title}
                        percentage={item.percentage}
                    />
                )}
                </div>
                <div className='progress-img-block'>
                    <img src="/images/tech-line.svg" alt="lines" className="lines"/>
                </div>
                <div className="codeflix-logo">
                    <img src="/images/tech-codeflix.svg" alt="codeflix"/>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AboutTech;