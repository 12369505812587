import React, { useEffect  } from 'react';

const HomeTrasfer = () => {
    const slideOne = {
        backgroundImage: "url('/images/slide-1.png')"
    };
    const slideTwo = {
        backgroundImage: "url('/images/slider-2.png')"
    };
    const slideThree = {
        backgroundImage: "url('/images/slider-3.png')"
    };
    const slideFour = {
        backgroundImage: "url('/images/slider-4.png')"
    };
  
    useEffect(() => {
        const slides = document.querySelectorAll(".slide");
const container = document.querySelector(".slider-images");
const speed = 250; 
let idx = 0; 
let clickable = true;

const resetAnimation = function () {
  this.style.WebkitAnimation = ""; 
  this.style.animation = ""; 
  this.removeEventListener("webkitAnimationEnd", resetAnimation); 
};

const setAnimation = (slide) => {
  slide.style.WebkitAnimation = `move ${speed}ms`; 
  slide.style.animation = `move ${speed}ms`; 
  slide.addEventListener("webkitAnimationEnd", resetAnimation);
};

const moveThis = (e) => {
  if (clickable && e.target.classList.contains("slide")) {
    clickable = false;

    if (idx >= slides.length - 1) {
      idx = 0;
    } else {
      idx++;
    }
    setAnimation(slides[idx]);

    slides.forEach((slide, index) => {
  if (index === idx) {
    slide.id = 'slide_1';
  } else {
    let distance = index - idx;
    let adjustedIndex = (slides.length + distance + 1) % slides.length;

    adjustedIndex = adjustedIndex === 0 ? slides.length : adjustedIndex;

    slide.id = `slide_${adjustedIndex}`;
  }
});

    setTimeout(() => {
      clickable = true;
    }, speed);
  }
};

container.addEventListener("click", moveThis);
    },[]);
  return (
    <section className="transfomation">
        <div className="hastag top">
            <div className="top-line">
                <p className="text">
                    # Cultivating Insights, Inspiring Transformation
                </p>
                <p className="text">
                    Cultivating Insights, Inspiring Transformation
                </p>
            </div>
        </div>
        <div className="top-line bottom-line">
                <p className="text">
                    # Cultivating Insights, Inspiring Transformation
                </p>
                <p className="text">
                    Cultivating Insights, Inspiring Transformation
                </p>
        </div>
        <div className="middle-txt">
            <p data-aos="fade-left">Cultivating Insights, Inspiring Transformation</p>
        </div>
        <div className='wrapper'>
            <div className="slider-images">
                <div className="slider-images">
                    <div id='slide_1' className="slide img_1 " style={slideOne}></div>
                    <div id='slide_2' className="slide img_2 "style={slideTwo}></div>
                    <div id='slide_3' className="slide img_3 "style={slideThree}></div>
                    <div id='slide_4'  className="slide img_4 "style={slideFour}></div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default HomeTrasfer;