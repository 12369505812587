import React from 'react';
import { baseUrl } from '../utils/constant';

const UpdateCards = ({cardImg,cradTitle,cardDtl,}) => {
  return (
    <div className="letest-box">
        <img src={baseUrl + cardImg} alt="" />
        <div className="letest-dtl">
            <h4 className="h6 bold">{cradTitle}</h4>
            <p className="p-small">{cardDtl}</p>
            <a className="">Read More <img src="/images/read-arrow.svg" /></a>
        </div>
    </div>
  )
}

export default UpdateCards;