import React from 'react';
import { baseUrl } from '../utils/constant';

const GloballyLogos = ({logos}) => {
  return (
    <div className="logos">
        <img src={baseUrl + logos} alt="logo" />
    </div>
  )
}

export default GloballyLogos;