import React, { useEffect, useState } from 'react';
import ServiceCard from './ServiceCard';
import Title from './Title';
import useApiList from '../hooks/useApiList';

const HomeService = () => {
    const { data } = useApiList();
    const [services,setServices] = useState([]);

    useEffect(()=>{
        setServices(data.serviceList);
    }, [data]);

  return (
    <section className="service-sec">
        <div className="wrapper">
            <Title 
                minibtnlabel="Services"
                title="Unlock Success "
                boldTitle='Explore'
                blueTitle='Our Services'
                paragraph="At the heart of our work is a commitment to creative excellence. We believe in infusing innovation and imagination into every service we provide."
            > 
            </Title>
            <div className="service-block">
                {services && services.map((item, index) =>
                    <ServiceCard
                    key={index}
                    img={item.image}
                    title={item.title}
                    subTitle={item.subTitle}
                    />
                )}
                <div className="service-card more" data-aos="zoom-in">
                    <h4 className="h4 bold">Explore More Services</h4>
                    <a className="custom-btn ">Click Here<img src="/images/arrow.svg" alt="arrow" /></a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default HomeService;