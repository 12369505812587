import Footer from "./Components/Footer";
import Header from "./Components/Header"
import { Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Service from "./Components/Service";
import AboutUs from "./Components/AboutUs";
import ContectUs from "./Components/ContectUs";
import Career from "./Components/Career";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import useApiList from './hooks/useApiList';
import { activeList, userDetails } from "./services/api/home";
import { ipUrl } from "./utils/constant";

function App() {

  const { updateList } = useApiList();

  useEffect(() => {
    activeList()
      .then((res) => {
        updateList(res.data.data);
      })
      .catch((error) => {
        console.log('========ERROR', error);
      });

    fetch(ipUrl)
      .then(response => response.json())
      .then(data => {
        userDetail(data.ip);
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });

    const blob = document.querySelector("#blob");
    const body = document.querySelector("body");

    body.addEventListener("mousemove", function (dets) {
        let half = blob.offsetWidth / 2;
        blob.style.left = `${dets.x - half}px`;
        blob.style.top = `${dets.y - half}px`;
    });

    Aos.init({
      duration: 1000,
    });

   }, []);

  const userDetail = (ip) => {
    const body = {
        ip,
        browser: navigator.userAgent
    }
    userDetails(body);
  }

  return (
    
      <div className="App">
        <div id="blob"></div>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/service" element={<Service />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contact-us" element={<ContectUs />} />
          </Routes>
        <Footer />
      </div>
   
  );
}

export default App;
