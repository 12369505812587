import { baseUrl } from '../utils/constant';
import axios from 'axios';
const axiosClient = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: 'application/json',
  }
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    console.error('Looks like there was a problem. Status Code: ' + res.status);
    return Promise.reject(error);
  }
);

export default axiosClient;
