import React from 'react'

const AboutValueSliderCard = ({valueTile,valueDtl,slideNumber}) => {
  return (
    <div className={" value-card slide-" + slideNumber}>
        <div className="value-dtl">
            <h4 className="h4">{valueTile}</h4>
            <p className="p-small">{valueDtl}</p>
        </div>
        <img src="/images/our-value-arrow.svg" alt="arrow" />
    </div>
  )
}

export default AboutValueSliderCard