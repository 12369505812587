import React from 'react'
import CreerBanner from './CreerBanner';
import CreerCurentOpning from './CreerCurentOpning';

const Career = () => {
  return (
    <>
        <CreerBanner />
        <CreerCurentOpning />
    </>
  )
}

export default Career;