import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import Title from './Title';


const AboutBanner = () => {
  return (
      <section className="about-banner">
        <div className="wrapper">
            <Title 
                minibtnlabel="About Us"
                title="Elevate IT"
                boldTitle='Simplify'
                blueTitle='Solutions'
                paragraph="Gorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet."
              > 
            </Title>
            <div className="about-img"data-aos="fade-left">
                <img src="/images/about-screen.png" alt="about" />
            </div>
            <Link className="scroll "><img src="/images/mouse.svg" /> Scroll Down</Link>
        </div>
      </section>
  )
}

export default AboutBanner;