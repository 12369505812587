import Chat from "./Chat";
import Hero from "./Hero";
import HomeAbout from "./HomeAbout";
import HomeClients from "./HomeClients";
import HomeGallary from "./HomeGallary";
import HomeGlobally from "./HomeGlobally";
import HomeService from "./HomeService";
import HomeTrasfer from "./HomeTrasfer";
import HomeUpdate from "./HomeUpdate";

const Home = () => {
  return (
   
   <>
      <Hero />
      <HomeAbout />
      <HomeService />
      <HomeGallary />
      <HomeGlobally />
      <HomeClients />
      <HomeTrasfer />
      <HomeUpdate />
      <Chat />
   </>
  
  )
}

export default Home;