import React from 'react';
import { baseUrl } from '../utils/constant';

const AboutGallerySliderCard = ({aboutSlideImg}) => {
  return (
    <div className="img-wrap">
        <img src={baseUrl + aboutSlideImg} alt="img" />
    </div>      
  )
}

export default AboutGallerySliderCard;