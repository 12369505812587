import React, { createContext, useState } from 'react';

const APIListContext = createContext({
    data: {},
    updateList: () => {}
})

export const APIListProvider = ({ children }) => {
    const [apiList, setApiList] = useState({})

    const handleList = (value) => {
        setApiList(value);
    };

    return (
        <APIListContext.Provider
            value={{
                data: apiList, updateList: handleList
            }}
        >
            {children}
        </APIListContext.Provider>
    )
}

export default APIListContext
